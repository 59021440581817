@import "../../../../mixins";

.ladder-cushion-safe {
  .modal__content {
    background-color: transparent;
  }

  .ladder-cushion-safe-bg {
    position: relative;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;
    background: linear-gradient(180deg, #D471CE 0%, #484BA6 100%);
    padding: 2px;

    > * {
      position: relative;
    }

    &::before {
      content: "";
      background: #fff;
      position: absolute;
      inset: 2px;
      border-radius: 16px;
      z-index: 0;
    }
  }

  .message {
    text-align: center;
    padding: 42px 16px 19px;
  }

  .cushion {
    top: -30px;
    position: absolute;
    left: 0;
    right: 0;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    filter: drop-shadow(0px 6px 0px rgba(0, 0, 0, 0.1));
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: center;

    img, svg {
      height: 30px;
      margin-right: 15px;
      filter: drop-shadow(0px 6px 0px rgba(0, 0, 0, 0.1));
    }

    span {
      font-weight: 900;
      font-size: 32px;
      line-height: 28px;
      color: #fff;
      @include shadow-text;
      //opacity: .6;
    }
  }

  .description {
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    @include shadow-text;
    text-transform: uppercase;
    margin-top: 13px;
  }
}
