@import "../../mixins";

#menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;

  .button-close {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  .logo-menu {
    height: 36px;
    filter: drop-shadow(0px 6px 0px rgba(0, 0, 0, 0.1));
  }

  .menu-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6em 1fr 6em;
    max-width: 500px;
    max-height: 100%;

    .header-transparent {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .menu-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ul {
        list-style-type: none;
        text-align: center;
        padding-left: 0;
        margin: 0;

        .list-ellipse {
          width: 8em;
          height: 0.2em;
          border-radius: 50%;
          background: #303030;
          opacity: 0.1;
        }

        li {
          text-align: center;
          font-family: 'Nunito', sans-serif;
          font-weight: 900;
          font-size: 1.5em;
          color: #ffffff;
          line-height: 33px;
          text-shadow: 0 3px 0 #454545;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          border-top: 6px;
          border-color: red transparent transparent transparent;
          position: relative;
        }
      }
    }

    .menu-terms {
      text-decoration: none;
      font-family: 'Nunito', sans-serif;
      font-weight: 900;
      font-size: 1.2em;
      color: #ffffff;
      @include shadow-text;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  #menu {
    .logo-menu {
      height: 30px;
      filter: none;
    }

    .menu-content {
      grid-template-rows: 60px 1fr 56px;

      .menu-list {
        ul {
          li {
            font-size: 22px;
            line-height: 30px;
          }
        }
      }
    }

    .menu-terms {
      font-size: 16px;
    }
  }
}
