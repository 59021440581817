.leaderboard-container {
  height: calc(100vh - 166px);
  overflow: auto;

    ul {
        list-style-type: none;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding: 0;
    }

    .leaderboard-scrolling {

    }
}
