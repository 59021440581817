.gameplay-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  &.options-images {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.modal-presence-wrapper {
  .modal {
    padding: 0;
  }

  .modal__content {
    margin: 0;
    max-width: 100%;
  }

  .content {
    max-width: 100%;
  }
}

.highlight-answer {
  > button {
    width: 100%;
  }
}
