.highlight {
  position: relative;
  width: 100%;
  text-align: center;

  &::after {
    content: "";
    background: rgba(#4dc1e9, .6);
    border: 2px solid #4dc1e9;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    margin: auto;
    opacity: 0;
    transition: all .2s ease-in-out;
    pointer-events: none;
    z-index: 2;
  }

  &.on {
    &::after {
      opacity: 1;
    }
  }
}
