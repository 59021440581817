.ladder-page {
  height: 100%;

  .content {
    overflow: auto;
    height: 100%;
  }
}

#ladder {
  margin-top: 44px;
    margin-left: 2.5em;
    margin-right: 2.5em;
  padding-bottom: 2em;
  position: relative;

    .ladder-content {
      position: relative;
        display: flex;
        flex-direction: column;

      .ladder-button-container {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .button-disabled-transparent {
        opacity: 0.3;
    }

    .none-margin {
        margin-top: 0px;
    }

  .player-left {
    position: absolute;
    top: 100%;
    left: -32px;
    transition: all .4s ease-in-out;
    z-index: 3;
  }

  .player-right {
    position: absolute;
    top: 100%;
    right: -32px;
    transition: all .2s ease-in;
    z-index: 3;
  }
}

.cushion-container {
  position: absolute;
  z-index: 20;
}

.cushion-float {
  height: 27px;

  &.inside-modal {
    filter: drop-shadow(0px 13.0887px 13.0887px rgba(0, 0, 0, 0.4));

    &.cushion-1 {
      //transform: rotate(-13deg);
    }

    &.cushion-2 {
      //transform: rotate(8deg);
    }
  }

  @media (max-width: 360px) {

    &.inside-modal {
      margin-top: 3px;
    }
  }
}
