#intro {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .intro-content {
    padding: 16px;
    color: #fff;
    text-align: center;
  }

  .header-transparent {
    .logo {
      height: auto;
      width: 300px;
      max-width: 90%;
      margin-top: 42px;
      margin-bottom: 20px;
    }
  }

  .title-intro {
    font-weight: 900;
    font-size: 36px;
    line-height: .8;
  }

  .description {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.2;
    max-width: 315px;
    margin-top: 14px;
    margin-left: auto;
    margin-right: auto;
  }

  .use-cases {
    display: flex;
    flex-direction: column;
    gap: 33px;
    margin-top: 22px;
  }

  .btn-case {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.3;
    background: #B042AD;
    border: 2px solid #6138B9;
    border-radius: 16px;
    min-height: 117px;
    padding: 28px 12px 10px;
    color: #fff;
    text-decoration: none;
    position: relative;

    span {
      font-weight: 900;
      font-size: 33px;
      line-height: 1;
      width: 54px;
      height: 54px;
      background: #303030;
      border: 2px solid #6138B9;
      box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -27px;
      left: -11px;

      &::before {
        content: "";
        width: 30px;
        height: 25px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 16px;
      }
    }
  }

  .mute-sounds {
    width: 50px;
    margin: 30px auto 0;

    img {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  #intro {
    .header-transparent {
      .logo {
        width: 211px;
        margin-top: 20px;
      }
    }

    .title-intro {
      font-size: 30px;
    }

    .btn-case {
      font-size: 16px;
    }
  }
}
