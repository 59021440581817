.button-close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
  border: 2px solid #861C41;
  box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: white;

  &.highlight::after {
    transform: scale(1.3);
  }

  &:hover {
    background: linear-gradient(180deg, #f3bec7 0%, #dc477b 100%);
  }
}
