.gameplay-container {
  padding: 16px 24px 24px;

  .gameplay-content {
    .gameplay-area {
      .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 60px;
        width: 40px;
        background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(180deg, #C76E67, #983E3D) border-box;
        border: 2px solid transparent;
        border-radius: 100px;
      }

      .timer-position {
        z-index: 2;
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
        margin-bottom: -40px;
        position: relative;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
      }

      .time-img {
        height: 44px;
        margin-top: -16px;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.35));
      }

      .timer-text {
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 8px;
        background: linear-gradient(180deg, #D37A72 0%, #872D2D 100%);
        background-clip: text;
        color: transparent;
      }

      .question-area {
        z-index: 1;
        padding: 16px;
        padding-top: 44px;
        background: #E7E7E7;
        border: 2px solid #FFFFFF;
        box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        min-height: 200px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;

        > * {
          position: relative;
        }

        &::before {
          content: "";
          position: absolute;
          top: 42px;
          left: 0;
          right: 0;
          height: 138px;
          width: 90%;
          background: #fff;
          filter: blur(25px);
          border-radius: 100px;
        }

        .question {
          font-style: normal;
          font-weight: 900;
          font-size: 22px;
          line-height: 26px;
          margin-top: 0.5em;
          text-align: center;

        }
      }

      .answer-area {
        background: #fff;
        box-shadow: 0 16px 32px rgba(0, 0, 0, 0.35);
        border-radius: 16px;
        min-height: 300px;
        margin-top: -24px;
        padding: 40px 16px 16px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .hint-area {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      gap: 16px;

      .highlight {
        width: auto;
      }
    }

    .players-area {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      gap: 16px;
      width: 240px;
      margin-left: auto;
      margin-right: auto;

      .player-container {
        width: 80px;

        .searching-opponent {
          height: 80px;

          .spinner {
            width: 81px;
            height: 81px;
          }
        }

        .player {
          width: 100%;

          .badge-rectangle {
            width: 44px;
            height: 34px;
            border-radius: 15px;
          }

          .slot-right, .slot-left {
            & > img {
              width: 34px;
              height: 34px;
            }
          }

          .slot-right {
            & > img {
              margin-right: 10px;
            }
          }

          .slot-left {
            & > img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .gameplay-container {
    padding-left: 16px;
    padding-right: 16px;

    .gameplay-content {
      .gameplay-area {
        .question-area {
          .question {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
