.ladder-button-container {
    display: flex;
    align-items: center;
  width: 100%;
  flex-wrap: wrap;

    .ladder-button {
      position: relative;
        z-index: 2;
      width: 100% ;

      .button-inner-overlay span {
        justify-content: flex-start;
        filter: none;
        text-shadow: none;
      }

        .count-start {
            position: initial;
            display: flex;
            align-items: center;
            margin-top: -5px;

            .icon-position {
                margin-left: 30px;
                padding-right: 15px;

                img {
                    height: 25px;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }
            }

            .count {
                margin-top: -5px;
            }
        }

        .count-end {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            width: 70px;
            flex-direction: row;
            right: 20px;
            align-items: center;
            margin-top: -8px;

            img {
                height: 30px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }

        }


    }
}

.selector-label {
    width: 68%;
    height: 31px;
    background: #FFFFFF;
    box-shadow: inset 0px -6px 0px rgba(84, 84, 84, 0.6);
  border-radius: 0px 0px 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  box-sizing: border-box;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #545454;
    border-radius: 0px 0px 16px 16px;
  }

    h4 {
        text-transform: uppercase;
        font-weight: 900;
    }
}
