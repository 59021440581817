#navbar {
  position: sticky;
  top: 0;
  z-index: 8;

  .menu {
    position: relative;
    background-color: #F8F8F8;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.35);
    display: grid;
    align-items: center;
    grid-template-columns: 80px 1fr 80px;
    border-radius: 0 0 2em 2em;
    height: 60px;
    padding-left: 16px;
    padding-right: 16px;

    &.has-stats {
      height: 80px;
      padding-bottom: 15px;
    }
  }

  .menu-standard {
    height: 60px;
  }

  .menu-full {
    height: 60px;
    padding-bottom: 20px;
    z-index: -1;
  }

  .slot-start {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .slot-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    max-width: 160px;
    max-height: 36px;
  }

  .slot-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .rewards-area {
    display: flex;
    margin-top: -1.7em;
    justify-content: space-evenly;
  }

  .avatar-container {
    width: 44px;

    .player {
      width: 100%;
    }
  }

  .stats-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    margin-top: -23px;
  }
}

.modal-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  opacity: 0;
  width: 100%;
  z-index: 99;
  transition: all .2s ease-in;

  &.open {
    left: 0;
    opacity: 1;

    .button-close {
      opacity: 1;
      transform: scale(1);
    }

    #menu .menu-content {
      opacity: 1;
    }
  }

  #background {
    left: 0;
    pointer-events: none;
  }

  .content {
    height: 100%;
  }

  .button-close {
    opacity: 0;
    transform: scale(0);
    transition: all .2s ease-in;
    transition-delay: .2s;
  }

  #menu .menu-content {
    opacity: 0;
    transition: all .2s ease-in;
    transition-delay: .2s;
  }
}


@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  #navbar {
    .stats-header {
      padding-left: 6px;
      padding-right: 6px;
      margin-top: -20px;
    }

    .menu {
      padding-left: 10px;
      padding-right: 10px;
      height: 48px;

      &.has-stats {
        height: 68px;
      }
    }

    .logo {
      max-width: 130px;
      max-height: 30px;
    }
  }
}
