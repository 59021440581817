@import "../../../mixins";

.leaderboard-row {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.35);
    border-radius: 16px;
    // width: 100%;
    height: 56px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
    align-items: center;
    border: 2px solid #303030;

    font-family: 'Nunito';
    font-style: normal;
    font-size: 18px;
    line-height: 28px;

    background: linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%);

    &.leaderboard-row-gold {
        background: linear-gradient(180deg, #FCDD3D 0%, #F9A00D 100%);
    }

    &.leaderboard-row-success {
        background: linear-gradient(181.05deg, #56D598 0.9%, #0E884E 121.01%);

        .slot-right {
            color: #FFFFFF;
            @include shadow-text;
        }
    }

    .slot {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1em;

        &.slot-left {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;

            .leaderboard-player-position-number {
                width: 1.8em;
                text-align: center;
            }
        }

        &.slot-right {
            font-weight: 900;
        }
    }



}

.user-list {
    margin-left: 1.8em;
    margin-right: 1.8em;
}

.user-leaderboard-position {
    width: 100%;
    max-width: 640px;
    height: 56px;
    bottom: 0;
    position: absolute;
    z-index: 1;
    border-radius: 16px 16px 0 0px;
    margin-left: 0em;
    margin-right: 0em;

    .slot {
        color: white;
    }
}

.user-leaderboard-champion {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-left: 1em;
    margin-right: 1em;
    background: linear-gradient(180deg, #FCDD3D 0%, #F9A00D 100%);

}
