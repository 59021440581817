.image-text-area {
  display: flex;
    flex-direction: column;
    margin-top: -28px;
}
.image-area-answer {
  height: 120px;
    border-radius: 8px;
    overflow: clip;
    display: flex;
    align-content: center;

  img{
    height: auto;
    width: 80px;
    object-fit: contain;
  }
}
.text-area-answer {
  font-family: 'Nunito';
font-style: normal;
font-weight: 900;
font-size: 20px;
color: #303030;
}
