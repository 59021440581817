#level-up {
  &.modal-open ~ div {
    opacity: 0;
  }

  p {
    margin: 0;
  }

  .modal {
    padding-top: 20%;
    overflow: hidden;
    max-height: 100vh;
  }

  .modal__content {
    font-weight: 900;
    margin: auto;
    max-width: 327px;
    text-align: center;
    background-color: transparent;
    position: relative;

    > * {
      position: relative;
      z-index: 1;
    }

    .bg-modal {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .rays {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
    }

    .rays-top {
      top: -38%;
      z-index: -1;
    }

    .rays-bottom {
      bottom: -30%;
      z-index: -1;
    }

    .content {
      display: inline-block;
    }

    .level {
      display: flex;
      justify-content: center;
      margin-top: -26%;
      margin-bottom: 24px;

      img {
        max-width: 100%;
        filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.25));
      }

      p {
        font-size: 80px;
        line-height: 109px;
        position: absolute;
        top: 10%;
        color: #fff;
        text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
      }
    }

    .title-modal {
      font-size: 44px;
      line-height: 1;
      background: linear-gradient(180deg, #FFB0A6 0%, #C40202 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 16px;
    }

    .points {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 19px;

      div {
        width: 120px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: linear-gradient(180deg, #FAD43B 0%, #F8990D 100%);
        border-radius: 16px;
        font-size: 10px;
        line-height: 14px;
      }

      span {
        font-size: 24px;
        line-height: 1.3;
        display: block;
        color: #fff;
        text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
      }

      img {
        width: 34px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        &:first-of-type {
          transform: translateX(50%);
        }

        &:nth-of-type(2) {
          transform: translateX(-50%);
        }
      }
    }

    .subtitle-modal {
      font-size: 22px;
      line-height: 1.2;
      margin-bottom: 16px;
    }

    .extra {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 210px;
      height: 24px;
      line-height: 22px;
      background: rgba(203, 206, 215, .15);
      border-radius: 100px;
      padding: 1px 8px;
      margin-bottom: 11px;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      img {
        height: 30px;
        margin: 0 8px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }

    .description {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.25;
      max-width: 270px;
      margin-left: auto;
      margin-right: auto;
    }

    .btn-container {
      transform: translateY(50%);
    }
  }
}

@media (max-width: 360px) {
  #level-up {
    .modal__content {
      .rays-bottom {
        transform: translateY(16%);
      }

      .level {
        margin-bottom: 10px;

        img {
          height: 130px;
        }

        p {
          font-size: 59px;
          line-height: 77px;
        }
      }

      .title-modal {
        margin-bottom: 10px;
      }

      .points {
        margin-bottom: 9px;

        img {
          width: 30px;
        }

        div {
          width: 104px;
          height: 52px;
          font-size: 9px;
        }

        span {
          font-size: 20px;
        }
      }

      .extra {
        margin-bottom: 17px;
      }

      .description {
        max-width: 215px;
      }

      .btn-container {
        transform: translateY(32%);
      }

      .button {
        height: 46px;
        border-radius: 13px;

        span {
          font-size: 17px;
          line-height: 23px;
        }
      }
    }
  }
}
